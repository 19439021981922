const TextInput = () => import('./components/TextInput.vue')
const NumberInput = () => import('./components/NumberInput.vue')
const AmountInput = () => import('./components/AmountInput.vue')
const TextareaInput = () => import('./components/TextareaInput.vue')
const SelectInput = () => import('./components/SelectInput.vue')
const MultipleSelect = () => import('./components/MultipleSelect.vue')
const DateTime = () => import('./components/DateTime.vue')
const DateTimeRange = () => import('./components/DateTimeRange.vue')

const Description = () => import('./components/Description.vue')
const ImageUpload = () => import('./components/ImageUpload.vue')
const FileUpload = () => import('./components/FileUpload.vue')
const Location = () => import('./components/Location.vue')
const MoneyInput = () => import('./components/MoneyInput.vue')
const DeptPicker = () => import('./components/DeptPicker.vue')
const UserPicker = () => import('./components/UserPicker.vue')
const SignPanel = () => import('./components/SignPannel.vue')

const SpanLayout = () => import('./components/SpanLayout.vue')
const TableList = () => import('./components/TableList.vue')

export default {
  // 基础组件
  TextInput,
  NumberInput,
  AmountInput,
  TextareaInput,
  SelectInput,
  MultipleSelect,
  DateTime,
  DateTimeRange,
  UserPicker,
  DeptPicker,
  // 高级组件
  Description,
  FileUpload,
  ImageUpload,
  MoneyInput,
  Location,
  SignPanel,
  SpanLayout,
  TableList
}
