<template>
  <node
    title="发起人"
    :is-root="true"
    :content="content"
    @selected="$emit('selected')"
    @insertNode="type => $emit('insertNode', type)"
    placeholder="所有人"
    :header-bgc="headerBgc"
    header-icon="el-icon-user-solid" />
</template>

<script>
import Node from './Node'

export default {
  name: 'RootNode',
  components: { Node },
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    content () {
      if (this.config.props.assignedUser.length > 0) {
        const texts = []
        this.config.props.assignedUser.forEach(org => texts.push(org.nickname))
        return String(texts).replaceAll(',', '、')
      } else {
        return '所有人'
      }
    },
    headerBgc () {
      if (this.$store.state.diagramMode === 'viewer') {
        return this.config.props.headerBgc
      } else {
        return '#576a95'
      }
    }
  },
  data () {
    return {}
  },
  methods: {}
}
</script>

<style scoped>

</style>
